import { Injectable } from '@angular/core';
import { getProviderIdAndSessionCodeFromToken } from '@app/utils/auth';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  CANDIDATE_COOKIE = 'x-auth-token';

  constructor(private cookieService: CookieService) {}

  checkCandidateToken(): boolean {
    return this.cookieService.check(this.CANDIDATE_COOKIE);
  }

  getCandidateToken(): string {
    return this.cookieService.get(this.CANDIDATE_COOKIE);
  }

  setCandidateToken(token: string): void {
    return this.cookieService.set(this.CANDIDATE_COOKIE, token);
  }

  getProviderIdAndSessionCode(): { providerId: string; sessionCode: string } {
    const token = this.getCandidateToken();
    const { providerId, sessionCode } =
      getProviderIdAndSessionCodeFromToken(token);

    return { providerId, sessionCode };
  }
}

export const getAuthToken = (
  providerId: string,
  sessionCode: string
): string => {
  return `${providerId}:${sessionCode}`;
};

export const getProviderIdAndSessionCodeFromToken = (
  token: string
): {
  providerId: string;
  sessionCode: string;
} => {
  const [providerId, sessionCode] = token.split(':');

  if (!/^\d+$/.test(sessionCode)) throw new Error('Invalid token');

  return { providerId, sessionCode };
};
